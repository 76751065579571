"use strict";

var _preact = require("preact");

const classNames = require("classnames");

const AddFiles = require("./AddFiles.js");

const AddFilesPanel = props => {
  return (0, _preact.h)("div", {
    className: classNames('uppy-Dashboard-AddFilesPanel', props.className),
    "data-uppy-panelType": "AddFiles",
    "aria-hidden": props.showAddFilesPanel
  }, (0, _preact.h)("div", {
    className: "uppy-DashboardContent-bar"
  }, (0, _preact.h)("div", {
    className: "uppy-DashboardContent-title",
    role: "heading",
    "aria-level": "1"
  }, props.i18n('addingMoreFiles')), (0, _preact.h)("button", {
    className: "uppy-DashboardContent-back",
    type: "button",
    onClick: () => props.toggleAddFilesPanel(false)
  }, props.i18n('back'))), (0, _preact.h)(AddFiles, props));
};

module.exports = AddFilesPanel;