"use strict";

var _preact = require("preact");

var _core = require("@uppy/core");

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */

/* eslint-disable jsx-a11y/click-events-have-key-events */
const FadeIn = require("./FadeIn.js");

const TransitionGroup = require("./TransitionGroup.js");

const packageJson = {
  "version": "2.1.1"
};
/**
 * Informer
 * Shows rad message bubbles
 * used like this: `uppy.info('hello world', 'info', 5000)`
 * or for errors: `uppy.info('Error uploading img.jpg', 'error', 5000)`
 *
 */

class Informer extends _core.UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);

    this.render = state => {
      return (0, _preact.h)("div", {
        className: "uppy uppy-Informer"
      }, (0, _preact.h)(TransitionGroup, null, state.info.map(info => (0, _preact.h)(FadeIn, {
        key: info.message
      }, (0, _preact.h)("p", {
        role: "alert"
      }, info.message, ' ', info.details && (0, _preact.h)("span", {
        "aria-label": info.details,
        "data-microtip-position": "top-left",
        "data-microtip-size": "medium",
        role: "tooltip" // eslint-disable-next-line no-alert
        ,
        onClick: () => alert(`${info.message} \n\n ${info.details}`)
      }, "?"))))));
    };

    this.type = 'progressindicator';
    this.id = this.opts.id || 'Informer';
    this.title = 'Informer'; // set default options

    const defaultOptions = {}; // merge default options with the ones set by user

    this.opts = { ...defaultOptions,
      ...opts
    };
  }

  install() {
    const {
      target
    } = this.opts;

    if (target) {
      this.mount(target, this);
    }
  }

}

Informer.VERSION = packageJson.version;
module.exports = Informer;