"use strict";

var _preact = require("preact");

const FilePreview = require("../../FilePreview.js");

const MetaErrorMessage = require("../MetaErrorMessage.js");

const getFileTypeIcon = require("../../../utils/getFileTypeIcon.js");

function FilePreviewAndLink(props) {
  return (0, _preact.h)("div", {
    className: "uppy-Dashboard-Item-previewInnerWrap",
    style: {
      backgroundColor: getFileTypeIcon(props.file.type).color
    }
  }, props.showLinkToFileUploadResult && props.file.uploadURL && (0, _preact.h)("a", {
    className: "uppy-Dashboard-Item-previewLink",
    href: props.file.uploadURL,
    rel: "noreferrer noopener",
    target: "_blank",
    "aria-label": props.file.meta.name
  }, (0, _preact.h)("span", {
    hidden: true
  }, props.file.meta.name)), (0, _preact.h)(FilePreview, {
    file: props.file
  }), (0, _preact.h)(MetaErrorMessage, {
    file: props.file,
    i18n: props.i18n,
    toggleFileCard: props.toggleFileCard,
    metaFields: props.metaFields
  }));
}

module.exports = FilePreviewAndLink;