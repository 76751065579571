"use strict";

var _preact = require("preact");

const getFileTypeIcon = require("../utils/getFileTypeIcon.js");

function FilePreview(props) {
  const {
    file
  } = props;

  if (file.preview) {
    return (0, _preact.h)("img", {
      className: "uppy-Dashboard-Item-previewImg",
      alt: file.name,
      src: file.preview
    });
  }

  const {
    color,
    icon
  } = getFileTypeIcon(file.type);
  return (0, _preact.h)("div", {
    className: "uppy-Dashboard-Item-previewIconWrap"
  }, (0, _preact.h)("span", {
    className: "uppy-Dashboard-Item-previewIcon",
    style: {
      color
    }
  }, icon), (0, _preact.h)("svg", {
    "aria-hidden": "true",
    focusable: "false",
    className: "uppy-Dashboard-Item-previewIconBg",
    width: "58",
    height: "76",
    viewBox: "0 0 58 76"
  }, (0, _preact.h)("rect", {
    fill: "#FFF",
    width: "58",
    height: "76",
    rx: "3",
    fillRule: "evenodd"
  })));
}

module.exports = FilePreview;